import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Link from "../components/LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../components/InViewObserver"

import { Expo, gsap } from 'gsap';

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">駐村編輯</h2>
          <h1 className="main_title">田野調查</h1>
          <h6 className="speaker">策展人/林書豪</h6>
        </div>

        <div className="page_content">

          <section className="row_area_s project_c_1_a">
            <div className="column_4">
              <div className="context">
                <p>藉由田野調查認識社區，累積文字紀錄並且不斷擴充在地人脈，並藉由良好的文字與攝影能力編輯成容易閱讀的地方誌，並且成為整個地方編輯發展的根基。</p>
                <p><strong>以「產業」和「社區」二大類作為田野調查對象，各五個地點，如下：</strong></p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_b row_gallery">
            <div className="column_6 row_area_s onset_left_1">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_1/project_c_1_1.jpg")} alt="剝皮寮歷史街區"/>
                <figcaption className="figcaption main left"><div className="text_inner">剝皮寮歷史街區</div></figcaption>
              </figure>
              <div className="column_5 onset_left_3">
                <div className="context">
                  <h5>200多年、老屋歷史街區、文資再利用</h5>
                  <p>剝皮寮歷史街區，至今留存著過去歷史當時的街道樣貌，豐富的新舊建築空間，開放舉辦多元類型的展覽和活動，成為在地社區民眾和來訪旅客的必經之地，此歷史街區未來可成為駐村編輯的策展場所，增進社群交流與互動 。</p>
                  <p className="tag"><i>#新舊歷史　#策展舞台　#社群連結</i></p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_c row_gallery">
            <div className="column_5 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_1/project_c_1_2.jpg")} alt="新富町文化市場"/>
                <figcaption className="figcaption main center"><div className="text_inner">新富町文化市場</div></figcaption>
              </figure>
              <div className="column_6">
                <div className="context">
                  <h5>市定古蹟、都市果核計畫基地、創意交流平台</h5>
                  <p>新富市場前身為「綠町食料品小賣市場」，是艋舺人生活中不可或缺的市場，由忠泰集團經營管理新富町文化市場，邀請藝術、設計、建築及相關創意工作者進駐與合作，擁有複合型的空間機能，有：展覽、教室、廚房、咖啡廳等，成為在地連結的交流平台，非常適合將鄰近菜市場的社區生活帶入其中，成為社區藝術交匯的空間。</p>
                  <p className="tag"><i>#新舊歷史 #社區生活 #策展舞台 #社群連結</i></p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_d row_gallery">
            <div className="column_7 row_area_s onset_left_1">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_1/project_c_1_3.jpg")} alt="艋舺服飾商區"/>
                <figcaption className="figcaption main left"><div className="text_inner">艋舺服飾商區</div></figcaption>
              </figure>
              <div className="column_6">
                <div className="context">
                  <h5>臺灣第一個成衣批發銷售市場、老屋商業街區、混合新舊商業</h5>
                  <p>艋舺服飾商圈，又名大理服飾街，曾是北台灣的服飾業重鎮，這裡是社區與產業緊密的生活空間，常見低樓層是商家、高樓層是住家，偶爾可見隱藏於此的青年創意小店或攤車，服飾多由中壯年的老闆作為批發商經營，店家多是傳統風格，試著透過邀請藝術家駐街創作和民眾參與藝術共創工作坊，重新提升街區的形象和新創意的產品，也帶動商圈的在地長輩們和青年的專長技能互助。</p>
                  <p className="tag"><i>#商業觀光 #社區生活</i></p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_f row_gallery">
            <div className="column_6 row_area_s offset_left_1">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_1/project_c_1_4.jpg")} alt="台北華西街夜市"/>
                <figcaption className="figcaption main right"><div className="text_inner">台北華西街夜市</div></figcaption>
              </figure>
              <div className="column_5 onset_left_3">
                <div className="context">
                  <h5>國際觀光客的夜市、老字號特色小吃</h5>
                  <p>華西街夜市以中國宮廷式的牌樓建築作為一大特色，已是很多國內外觀光客最鍾愛的景點之一，與鄰近街道合併成為一個大型特色商街，現場可以看見台灣道地的夜市文化，吸引許多外國旅客的來訪，適合以商業觀光作為發想主軸，利用半室內的街道雨棚，將各店家、小吃美食作為駐村編輯的策展空間和元素，強化地方品牌特色。</p>
                  <p className="tag"><i>#商業觀光 #社區生活 #策展舞台</i></p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_g row_gallery">
            <div className="column_6 row_area_s onset_left_2">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_1/project_c_1_5.jpg")} alt="艋舺老街"/>
                <figcaption className="figcaption main center"><div className="text_inner">艋舺老街</div></figcaption>
              </figure>
              <div className="column_6">
                <div className="context">
                  <h5>台北第一街、傳統洋樓建築、老店與青年創業共存</h5>
                  <p>艋舺老街是全台北市最老的街，也是艋舺興盛的起點，可以看見傳統雜貨小販、糕餅店、香鋪、百年藥行等，極具歷史風味的老街區，非常適合透過田野調查了解昔日台北城的發展，現在寧靜的老地方，非常具有傳統社區的生活感，當然也有許多青年創業小店進駐，可以在艋舺老街號召青年創業者群們，以創意街區的營造和行動，將藝術和社區共融的願景落實和發展。</p>
                  <p className="tag"><i>#新舊歷史 #社區生活</i></p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_q row_gallery">
            <div className="column_5 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_1/project_c_1_6.jpg")} alt="青草巷"/>
                <figcaption className="figcaption main left"><div className="text_inner">青草巷</div></figcaption>
              </figure>
              <div className="column_7">
                <div className="context">
                  <h5>救命街、保健保養</h5>
                  <p>青草是早期民間重要的醫療方式，買藥草回去熬煮來治療疾病，現在多變為養生食品，強調保健保養和放鬆舒壓的功效，也讓更多現代人開始接觸藥草，當然成為田野調查必訪之地，在青草巷可以了解人們生活樣貌變化的軌跡，老巷弄裡看見滿滿因時間所累積的藥草罐，未來可以選擇中醫師作為藝術家進駐的角色，將專長技能化為創造力，轉譯傳統智慧的契機，更能重新看見青草巷的另一種可能。</p>
                  <p className="tag"><i>#商業觀光 #社區生活</i></p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_r row_gallery">
            <div className="column_8 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_1/project_c_1_7.jpg")} alt="龍山寺"/>
                <figcaption className="figcaption main center"><div className="text_inner">龍山寺</div></figcaption>
              </figure>
              <div className="column_4 onset_left_4">
                <div className="context">
                  <h5>艋舺清代歷史的重要見證者、觀光景點</h5>
                  <p>龍山寺，為漢人渡海來臺墾殖的重要地標，也是當地居民信仰、活動、集會的重要場所，建築上有很多精緻的雕刻、跨時代的寺廟藝術，我們期待現在眾人所知的龍山寺，媒合青年設計師開發創意周邊商品，成為未來藝術節策展的在地行銷之特色產品，也讓來訪旅客獲得值得珍藏的紀念品。</p>
                  <p className="tag"><i>#新舊歷史 #商業觀光 #社區生活</i></p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_s row_gallery">
            <div className="column_5 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_1/project_c_1_8.jpg")} alt="萬華車站"/>
                <figcaption className="figcaption main center"><div className="text_inner">萬華車站</div></figcaption>
              </figure>
              <div className="column_6">
                <div className="context">
                  <h5>歷史追溯至清朝艋舺、火車站已是第六代</h5>
                  <p>萬華車站，為台北與新北交會的第一站，曾是客商雲集之地，因工商業逐日發達，火車站成為生活裡的重要交通建設，從早期的木構造和洋混搭式建築，到現今混凝土高樓建築，有台北市婦女館、凱達飯店設立於此，如何運用車站的公共空間成為策展的潛力空間，值得邀請公部門與進駐藝術家共同思考，也能因此在交通樞杻給予大眾共同感受藝術在生活之中。</p>
                  <p className="tag"><i>#商業觀光 #社區生活 #策展舞台</i></p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_k row_gallery">
            <div className="column_4 row_area_s onset_left_4">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_1/project_c_1_9.jpg")} alt="糖廍文化園區"/>
                <figcaption className="figcaption main center"><div className="text_inner">糖廍文化園區</div></figcaption>
              </figure>
              <div className="column_6">
                <div className="context">
                  <h5>台灣最北的製糖遺構、糖業文化展示館、藝文倉庫</h5>
                  <p>「糖廍文化園區」來自在地社區民眾的發起，這項文資建築的保存運動，是公私協力共同完成的努力，看見園區有藝文團體(曉劇場)進駐，也附有展示館持續推廣糖業文化，昔日月台周邊綠地成了社區休憩空間，可以將園區作為社區交流發展的基地，不斷鼓勵更多在地社區居民一起發揮各所長，為社區找到更多的可能。</p>
                  <p className="tag"><i>#新舊歷史 #社區生活 #策展舞台 #社群連結</i></p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_k row_gallery">
            <div className="column_8 row_area_s ">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_1/project_c_1_10.jpg")} alt="艋舺青山宮"/>
                <figcaption className="figcaption main center"><div className="text_inner">艋舺青山宮</div></figcaption>
              </figure>
              <div className="column_6">
                <div className="context">
                  <h5>艋舺青山王祭、艋舺大拜拜、傳統與現代的火花</h5>
                  <p>艋舺青山宮創立於1856年，由青山王坐鎮，為當地人重要的精神寄託，最著名的青山王暗訪繞境，俗稱「艋舺大拜拜」，近年來吸引不少青年加入這場文化信仰祭典，拜於年輕化的視覺形象宣傳，也成為文化愛好者廣為流傳的盛事，每年農曆10月於萬華各街道的繞境儀式，串連社區的空間，非常值得作為活動策劃的借鏡，更能作為藝術與文化於地方長期發展的重要資產。</p>
                  <p className="tag"><i>#新舊歷史 #社區生活 #策展舞台</i></p>
                </div>
              </div>
            </div>
          </section>

        </div>


        <div className="page_footer">
          <Link to="/project_c_2" className="next_page">
            <div className="next_title">職人黃頁</div>
            <div className="next_owner">策展人/林書豪</div>
          </Link>
        </div>

      </div>

    );
  }

}


export default Page
